// import { fetchCommunityDataBySlug } from '@/services/communitiesService';
import React from 'react';

import config from '@/utility/config';
import { checkIfIndexable } from '@/utility/helpers';

import PageMetaTags from '@/components/common/PageMetaTags';
import { TAB_PAGE_VIEW_EVENT_NAME } from '@/components/features/CommunityPage/constants';
import useTrackTabPageView from '@/components/features/CommunityPage/hooks/useTrackTabPageView';
import CommunityPageLayout from '@/components/features/CommunityPage/layouts/CommunityPageLayout';
import AuthNavbarPageLayout from '@/components/features/Navbar/AuthNavbarPageLayout';

import HomeTab from '../../components/features/CommunityPage/tabContents/HomeTab';
import { getCommunityStaticProps } from './utils';

const CommunityPage = ({
  communityPublicPageData,
  discountPercentageForHighestInterval,
  hasScrolledPastBanner,
  userCommunitiesData,
  genericTrackingFields,
  mutateCommunityData
}) => {
  useTrackTabPageView({
    eventName: TAB_PAGE_VIEW_EVENT_NAME.HOME,
    communityId: communityPublicPageData?._id
  });

  const communityLink = communityPublicPageData?.slug;

  return (
    <div>
      {/* Meta tags for SEO */}
      <PageMetaTags
        {...communityPublicPageData?.metadata}
        canonicalUrl={`${config.siteBaseUrl}${communityLink}`}
        description={communityPublicPageData?.description}
        indexable={checkIfIndexable(communityPublicPageData)}
      />
      <HomeTab
        communityPublicPageData={communityPublicPageData}
        discountPercentageForHighestInterval={
          discountPercentageForHighestInterval
        }
        hasScrolledPastBanner={hasScrolledPastBanner}
        genericTrackingFields={genericTrackingFields}
        userCommunitiesData={userCommunitiesData}
        mutateCommunityData={mutateCommunityData}
      />
    </div>
  );
};

CommunityPage.getLayout = function getLayout(page) {
  const communityPublicPageData = page.props.communityPublicPageData;
  return (
    <AuthNavbarPageLayout
      communityInfo={communityPublicPageData}
      isCommunityPageView={true}>
      {(
        userCommunitiesData,
        setHasScrollPastBanner,
        hasScrolledPastBanner
      ) => (
        <CommunityPageLayout
          communityPublicPageData={communityPublicPageData}
          showBanner
          userCommunitiesData={userCommunitiesData}
          setHasScrollPastBanner={setHasScrollPastBanner}
          hasScrolledPastBanner={hasScrolledPastBanner}>
          {page}
        </CommunityPageLayout>
      )}
    </AuthNavbarPageLayout>
  );
};

export async function getStaticPaths() {
  return {
    paths: [],
    fallback: 'blocking'
  };
}

export const getStaticProps = getCommunityStaticProps;
export default CommunityPage;
